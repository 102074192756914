import React from 'react'

export default function Footer() {
    return (
        <div>
            <footer style={footerStyle}>
                <div style={footerSubStyle}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <p style={pStyle}><strong>Copyright &copy; 2019 <a href="https://lifelyn.com"
                                            target="_blank" rel="noopener noreferrer"
                                            style={aStyle}>Lifeline</a>.</strong> All rights reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

const footerStyle = {
    background: '#111',
    padding: '0 0 0 0',
    color: '#bbb'
}

const footerSubStyle = {
    background: '#0a0a0a',
    textShadow: 'none',
    color: '#5d5d5d',
    padding: '0',
    paddingTop: '10px',
    paddingBottom: '10px',
    margin: '20px 0 0 0'
}

const aStyle = {
    color: '#888888'
}

const pStyle = {
    fontSize: '14px',
    margin: '0',
    padding: '0'
}