import React, { Component } from 'react';

class Masonry extends Component {
    constructor(props) {
        super(props);

        this.state = {
            h1: 0, h2: 0, h3: 0, h4: 0, h5: 0,b1: 0, b2: 0, b3: 0, b4: 0, b5: 0,
            d: 0, dl: 0, gw: 0, gb: 0, cb: 0,pb: 0, gn: 0, cn: 0, pn: 0, al: 0,
            bc: 0, k1: 0, k2: 0, mtd: 'rnk'
        }
    }

    onChange = e => {
        this.setState({
            [e.target.name]: Number(e.target.value)
        });
    }

    onMethodChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render() {
        const {h1, h2, h3, h4, h5,b1, b2, b3, b4, b5, d, dl, gw, gb, pb, gn, cn, pn, al, bc, k1, k2, mtd} = this.state;
        const htot = h1 + h2 + h3;
        const btot = b1 + b2 + b3 + b4 + b5;
        
        const a1 = h1 * b3;
        const w1 = a1 * gw;
        const r1 = b1 + b2 + b3/2;
        const m1 = w1 * r1;

        const a2 = h1 * b4 / 2;
        const w2 = a2 * gw;
        const r2 = b1 + b2 + b3 + b4 / 3;
        const m2 = w2 * r2;

        const a3 = h1 * b2 / 2;
        const w3 = a3 * gw;
        const r3 = b1 + 2 * b2 / 3;
        const m3 = w3 * r3;

        let a4, w4, r4, m4, a5, w5, r5, m5, a6, w6, r6, m6;
        if (mtd === 'rnk') {
            a4 = ((b4 + b5) * (b4 + b5) * Math.tan(al*Math.PI/180)) / 2;
            w4 = a4 * gb;
            r4 = b1 + b2 + b3 + 2 * (b4 + b5) / 3;
            m4 = w4 * r4;

            a5 = h1 * b4 / 2;
            w5 = a5 * gb;
            r5 = b1 + b2 + b3 + 2 * b4 / 3;
            m5 = w5 * r5;

            a6 = h1 * b5;
            w6 = a6 * gb;
            r6 = b1 + b2 + b3 + b4 + b5 / 2;
            m6 = w6 * r6;
        } else if (mtd === 'clb') {
            a4 = 0;
            w4 = 0;
            r4 = 0;
            m4 = 0;

            a5 = 0;
            w5 = 0;
            r5 = 0;
            m5 = 0;

            a6 = 0;
            w6 = 0;
            r6 = 0;
            m6 = 0;
        }

        const a7 = btot * (h2 + h3);
        const w7 = a7 * gw;
        const r7 = btot / 2;
        const m7 = w7 * r7;

        const a8 = h2 * b5 / 2;
        const w8 = a8 * (gb - gw);
        const r8 = b1 + b2 + b3 + b4 + 2 * b5 / 3;
        const m8 = w8 * r8;

        const a9 = h4 * b1 / 2;
        const w9 = a9 * -1 * gw;
        const r9 = b1 / 3;
        const m9 = w9 * r9;

        const alp = al*Math.PI/180;
        const tht = Math.atan(b4 / h1);
        const phb = pb*Math.PI/180;
        const bta = Math.PI/2 - tht;
        const dlt = dl*Math.PI/180;
        const phn = pn*Math.PI/180;
        
        let numka, denka, ka, pa, pah, pav, mto, fso, kp, pp, fss, ecc, qtoe, qhl, fsb, rv, mv, at, wt, mt;
        if (mtd === 'rnk') {
            // say = Math.asin(Math.sin(alp) / Math.sin(phb)) - alp + 2 * tht;
            // numka = Math.cos(alp - tht) * Math.sqrt(1 + Math.sin(phb) * Math.sin(phb) - 2 * Math.sin(phb) * Math.cos(say));
            // denka = Math.cos(tht) * Math.cos(tht) * (Math.cos(alp) + Math.sqrt(Math.sin(phb) * Math.sin(phb) - Math.sin(alp) * Math.sin(alp)));
            numka = Math.cos(alp) * (Math.cos(alp) - Math.sqrt(Math.cos(alp) * Math.cos(alp) - Math.cos(phb) * Math.cos(phb)));
            denka = Math.cos(alp) + Math.sqrt(Math.cos(alp) * Math.cos(alp) - Math.cos(phb) * Math.cos(phb));
            ka = numka / denka;
            pa = ka * ((htot + (b4 + b5) * Math.tan(al*Math.PI/180)) ** 2) * gb / 2;
            pah = pa * Math.cos(alp);
            pav = pa * Math.sin(alp);
            rv = btot;
            mv = pav * rv;
            at = a1 + a2 + a3 + a4 + a5 + a6 + a7 + a8 + a9;
            wt = w1 + w2 + w3 + w4 + w5 + w6 + w7 + w8 + w9 + pav;
            mt = m1 + m2 + m3 + m4 + m5 + m6 + m7 + m8 + m9 + mv;
            mto = pah * (htot + (b4 + b5) * Math.tan(al*Math.PI/180)) / 3;
            fso = mt / mto;
            kp = Math.tan(Math.PI/4 + phn / 2) * Math.tan(Math.PI/4 + phn / 2);
            pp = kp * gn * d * d / 2 + 2 * cn * d * Math.sqrt(kp);
            fss = (wt * Math.tan(k1 * phn) + btot * k2 * cn + pp) / pah;
            ecc = (btot / 2) - (mt - mto) / wt;
            qtoe = (wt / btot) * (1 + 6 * ecc / btot);
            qhl = (wt / btot) * (1 - 6 * ecc / btot);
            fsb = bc / qtoe;
        } else if (mtd === 'clb') {
            numka = Math.sin(bta + phb) * Math.sin(bta + phb);
            denka = Math.sin(bta) * Math.sin(bta) * Math.sin(bta - dlt) * ((1 + Math.sqrt((Math.sin(phb + dlt) * Math.sin(phb - alp)) / (Math.sin(bta - dlt) * Math.sin(alp + bta))))**2);
            ka = numka / denka;
            pa = ka * (htot ** 2) * gb / 2;
            pah = pa * Math.cos(tht + dlt);
            pav = pa * Math.sin(tht + dlt);
            rv = b1 + b2 + b3 + b4 - (htot / 3 - (h2 + h3)) * Math.tan(tht);
            mv = pav * rv;
            at = a1 + a2 + a3 + a4 + a5 + a6 + a7 + a8 + a9;
            wt = w1 + w2 + w3 + w4 + w5 + w6 + w7 + w8 + w9 + pav;
            mt = m1 + m2 + m3 + m4 + m5 + m6 + m7 + m8 + m9 + mv;
            mto = pah * htot / 3;
            fso = mt / mto;
            kp = Math.tan(Math.PI/4 + phn / 2) * Math.tan(Math.PI/4 + phn / 2);
            pp = kp * gn * d * d / 2 + 2 * cn * d * Math.sqrt(kp);
            fss = (wt * Math.tan(k1 * phn) + btot * k2 * cn + pp) / pah;
            ecc = (btot / 2) - (mt - mto) / wt;
            qtoe = (wt / btot) * (1 + 6 * ecc / btot);
            qhl = (wt / btot) * (1 - 6 * ecc / btot);
            fsb = bc / qtoe;
        }

        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-5 offset-md-1">
                        <img src="/images/masonry_retaining_wall.jpg" className="img-fluid" alt=""/>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <div className="card p-2">
                            <h5>Factor of Safeties</h5>
                            <p>Overturning = {fso.toFixed(3)}</p>
                            <p>Sliding = {fss.toFixed(3)}</p>
                            <p>Bearing capacity = {fsb.toFixed(3)}</p>
                            <hr/>
                            <h5>Extra</h5>
                            <p>Eccentricity = {ecc.toFixed(3)}</p>
                            <p>Pressure at toe = {qtoe.toFixed(3)}</p>
                            <p>Pressure at heel = {qhl.toFixed(3)}</p>
                        </div>
                    </div>
                </div>
                
                <div className="card card-body mb-2">
                    <div className="row">
                        <div className="col-md-2">
                            <div className="form-group row">
                                <label htmlFor="h1"
                                    className="col-md-4 col-form-label text-md-right">H1</label>

                                <div className="col-md-8">
                                    <input id="h1" type="number" step="0.00001" className="form-control"
                                        name="h1" onChange={this.onChange} required />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="h2"
                                    className="col-md-4 col-form-label text-md-right">H2</label>

                                <div className="col-md-8">
                                    <input id="h2" type="number" step="0.00001" className="form-control"
                                        name="h2" onChange={this.onChange} required />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="h3"
                                    className="col-md-4 col-form-label text-md-right">H3</label>

                                <div className="col-md-8">
                                    <input id="h3" type="number" step="0.00001" className="form-control"
                                        name="h3" onChange={this.onChange} required />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="h4"
                                    className="col-md-4 col-form-label text-md-right">H4</label>

                                <div className="col-md-8">
                                    <input id="h4" type="number" step="0.00001" className="form-control"
                                        name="h4" onChange={this.onChange} required />
                                </div>
                            </div>
                        </div>
                    
                        <div className="col-md-2">
                            <div className="form-group row">
                                <label htmlFor="h5"
                                    className="col-md-4 col-form-label text-md-right">H5</label>

                                <div className="col-md-8">
                                    <input id="h5" type="number" step="0.00001" className="form-control"
                                        name="h5" onChange={this.onChange} required />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="b1"
                                    className="col-md-4 col-form-label text-md-right">B1</label>

                                <div className="col-md-8">
                                    <input id="b1" type="number" step="0.00001" className="form-control"
                                        name="b1" onChange={this.onChange} required />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="b2"
                                    className="col-md-4 col-form-label text-md-right">B2</label>

                                <div className="col-md-8">
                                    <input id="b2" type="number" step="0.00001" className="form-control"
                                        name="b2" onChange={this.onChange} required />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="b3"
                                    className="col-md-4 col-form-label text-md-right">B3</label>

                                <div className="col-md-8">
                                    <input id="b3" type="number" step="0.00001" className="form-control"
                                        name="b3" onChange={this.onChange} required />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className="form-group row">
                                <label htmlFor="b4"
                                    className="col-md-4 col-form-label text-md-right">B4</label>

                                <div className="col-md-8">
                                    <input id="b4" type="number" step="0.00001" className="form-control"
                                        name="b4" onChange={this.onChange} required />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="b5"
                                    className="col-md-4 col-form-label text-md-right">B5</label>

                                <div className="col-md-8">
                                    <input id="b5" type="number" step="0.00001" className="form-control"
                                        name="b5" onChange={this.onChange} required />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="d"
                                    className="col-md-4 col-form-label text-md-right">D</label>

                                <div className="col-md-8">
                                    <input id="d" type="number" step="0.00001" className="form-control"
                                        name="d" onChange={this.onChange} required />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="dl"
                                    className="col-md-4 col-form-label text-md-right">δ</label>

                                <div className="col-md-8">
                                    <input id="dl" type="number" step="0.00001" className="form-control"
                                        name="dl" onChange={this.onChange} required />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className="form-group row">
                                <label htmlFor="gw"
                                    className="col-md-4 col-form-label text-md-right">γw</label>

                                <div className="col-md-8">
                                    <input id="gw" type="number" step="0.00001" className="form-control"
                                        name="gw" onChange={this.onChange} required />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="gb"
                                    className="col-md-4 col-form-label text-md-right">γb</label>

                                <div className="col-md-8">
                                    <input id="gb" type="number" step="0.00001" className="form-control"
                                        name="gb" onChange={this.onChange} required />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="cb"
                                    className="col-md-4 col-form-label text-md-right">Cb</label>

                                <div className="col-md-8">
                                    <input id="cb" type="number" step="0.00001" className="form-control"
                                        name="cb" onChange={this.onChange} required />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="pb"
                                    className="col-md-4 col-form-label text-md-right">φb</label>

                                <div className="col-md-8">
                                    <input id="pb" type="number" step="0.00001" className="form-control"
                                        name="pb" onChange={this.onChange} required />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className="form-group row">
                                <label htmlFor="al"
                                    className="col-md-4 col-form-label text-md-right">α</label>

                                <div className="col-md-8">
                                    <input id="al" type="number" step="0.00001" className="form-control"
                                        name="al" onChange={this.onChange} required />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="gn"
                                    className="col-md-4 col-form-label text-md-right">γn</label>

                                <div className="col-md-8">
                                    <input id="gn" type="number" step="0.00001" className="form-control"
                                        name="gn" onChange={this.onChange} required />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="cn"
                                    className="col-md-4 col-form-label text-md-right">Cn</label>

                                <div className="col-md-8">
                                    <input id="cn" type="number" step="0.00001" className="form-control"
                                        name="cn" onChange={this.onChange} required />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="pn"
                                    className="col-md-4 col-form-label text-md-right">φn</label>

                                <div className="col-md-8">
                                    <input id="pn" type="number" step="0.00001" className="form-control"
                                        name="pn" onChange={this.onChange} required />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className="form-group row">
                                <label htmlFor="bc"
                                    className="col-md-4 col-form-label text-md-right">BC</label>

                                <div className="col-md-8">
                                    <input id="bc" type="number" step="0.00001" className="form-control"
                                        name="bc" onChange={this.onChange} required />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="k1"
                                    className="col-md-4 col-form-label text-md-right">k1</label>

                                <div className="col-md-8">
                                    <input id="k1" type="number" step="0.00001" className="form-control"
                                        name="k1" onChange={this.onChange} required />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="k2"
                                    className="col-md-4 col-form-label text-md-right">k2</label>

                                <div className="col-md-8">
                                    <input id="k2" type="number" step="0.00001" className="form-control"
                                        name="k2" onChange={this.onChange} required />
                                </div>
                            </div>

                            <div className="form-group-row">
                                <select id="mtd" name="mtd"
                                    className="form-control" onChange={this.onMethodChange} required>
                                    <option>Select Method</option>
                                    <option value="rnk">Rankine</option>
                                    <option value="clb">Coulomb</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            
                <div className="card card-body my-2">
                    <h5 className="card-title">Weights and Moment Arms</h5>
                    <div className="card-body table-responsive">
                        <table className="table mb-4 table-bordered">
                            <thead className="thead-light">
                                <tr>
                                    <th>Section</th>
                                    <th>Area</th>
                                    <th>Weight</th>
                                    <th>Moment Arm</th>
                                    <th>Moment</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>{a1.toFixed(3)}</td>
                                    <td>{w1.toFixed(3)}</td>
                                    <td>{r1.toFixed(3)}</td>
                                    <td>{m1.toFixed(3)}</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>{a2.toFixed(3)}</td>
                                    <td>{w2.toFixed(3)}</td>
                                    <td>{r2.toFixed(3)}</td>
                                    <td>{m2.toFixed(3)}</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>{a3.toFixed(3)}</td>
                                    <td>{w3.toFixed(3)}</td>
                                    <td>{r3.toFixed(3)}</td>
                                    <td>{m3.toFixed(3)}</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>{a4.toFixed(3)}</td>
                                    <td>{w4.toFixed(3)}</td>
                                    <td>{r4.toFixed(3)}</td>
                                    <td>{m4.toFixed(3)}</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>{a5.toFixed(3)}</td>
                                    <td>{w5.toFixed(3)}</td>
                                    <td>{r5.toFixed(3)}</td>
                                    <td>{m5.toFixed(3)}</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>{a6.toFixed(3)}</td>
                                    <td>{w6.toFixed(3)}</td>
                                    <td>{r6.toFixed(3)}</td>
                                    <td>{m6.toFixed(3)}</td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>{a7.toFixed(3)}</td>
                                    <td>{w7.toFixed(3)}</td>
                                    <td>{r7.toFixed(3)}</td>
                                    <td>{m7.toFixed(3)}</td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>{a8.toFixed(3)}</td>
                                    <td>{w8.toFixed(3)}</td>
                                    <td>{r8.toFixed(3)}</td>
                                    <td>{m8.toFixed(3)}</td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>{a9.toFixed(3)}</td>
                                    <td>{w9.toFixed(3)}</td>
                                    <td>{r9.toFixed(3)}</td>
                                    <td>{m9.toFixed(3)}</td>
                                </tr>
                                <tr>
                                    <td>Pav</td>
                                    <td></td>
                                    <td>{pav.toFixed(3)}</td>
                                    <td>{rv.toFixed(3)}</td>
                                    <td>{mv.toFixed(3)}</td>
                                </tr>
                                <tr>
                                    <td>Total</td>
                                    <td>{at.toFixed(3)}</td>
                                    <td>{wt.toFixed(3)}</td>
                                    <td></td>
                                    <td>{mt.toFixed(3)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            
                
            </div>
        );
    }
}

export default Masonry;
