import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Masonry from './components/geo/retaining_wall/Masonry';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';

function App() {
  return (
    <Router>
      <div className="App">
        <div style={mainStyle}>
          <Navbar />
          <div style={contentStyle}>
            <Route exact path="/" component={Masonry} />
            <Route exact path="/geotech" component={Masonry} />
          </div>
        </div>
        <div style={paddingStyle}></div>
        <Footer />
      </div>
    </Router>
  );
}

const mainStyle = {
  minHeight: 'calc(100vh - 176px)'
}

const paddingStyle = {
  paddingBottom: '115px'
}

const contentStyle = {
  marginTop: '80px'
}

export default App;
